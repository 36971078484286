import {
  Alert,
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Heading,
  IconButton,
  Image,
  Img,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import Vapi from "@vapi-ai/web";
import Lottie from "lottie-react";
import {
  ArrowUpRightIcon,
  CheckIcon,
  ChevronUpIcon,
  MicIcon,
  MicOffIcon,
  PhoneOffIcon,
  PhoneOutgoingIcon,
  Volume2Icon,
  XIcon,
} from "lucide-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Millis from "@millisai/web-sdk";
import { useSearchParams } from "react-router-dom";
import AIAnimation from "../../assets/animation/ai.json";
import { axios } from "../../utils/axios.loader";
import Logo from "../../assets/img/logo-full.png";
import Markdown from "react-markdown";
import mixpanel from "mixpanel-browser";
import _ from "lodash";
import Jiggle from "~/@components/Jiggle";
import {
  useAudioWaveform,
  useMultibandTrackVolume,
} from "~/@components/UseTrackVolume";
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
} from "mic-check";
import { StarRating } from "~/components/star-rating";
import { LiveKitRoom, useLocalParticipant, useRoomContext, useTrackTranscription, useVoiceAssistant } from "@livekit/components-react";
import { RemoteParticipant, RemoteTrackPublication, RoomEvent, Track } from "livekit-client";

function useMicrophoneStatus(state: string) {
  const [status, setStatus] = useState<string>("pending");
  const [error, setErrorMessage] = useState<
    { title: string; description: string; link?: string } | undefined
  >();

  const resetError = () => {
    setErrorMessage(undefined);
  };

  useEffect(() => {
    if (state === "online") {
      (async () => {
        requestMediaPermissions({
          audio: true,
          video: false,
        })
          .then((v) => {
            setStatus("success");
            setErrorMessage(undefined);
          })
          .catch((err: MediaPermissionsError) => {
            setStatus("error");
            if (err.type === MediaPermissionsErrorType.SystemPermissionDenied) {
              setErrorMessage({
                title: "Can't use your microphone",
                description: `Your browser might not have access to your microphone. To fix this problem, enable in System Preferences`,
                link: "https://docs.electricspeech.co/how-to-provider-microphone-permission-to-your-browser",
              });
            } else if (
              err.type === MediaPermissionsErrorType.CouldNotStartVideoSource
            ) {
              setErrorMessage({
                title: "Another application is using your microphone",
                description: `Another application or browser tab is using the microphone`,
              });
            } else if (
              err.type === MediaPermissionsErrorType.UserPermissionDenied
            ) {
              setErrorMessage({
                title: "Microphone access is denied",
                description: `App requires access to your microphone. Please click on the browser settings and allow microphone`,
                link: "https://docs.electricspeech.co/allow-microphone-access-to-your-browser",
              });
            } else if (err.type === MediaPermissionsErrorType.Generic) {
              setErrorMessage({
                title: "Microphone cannot be detected",
                description: `Cannot detect microphone. Please contact us for any assistance`,
              });
            } else {
              setErrorMessage({
                title: "Microphone cannot be used",
                description: `Cannot use active microphone. Please contact us for any assistance`,
              });
            }
          });
      })();
    }
  }, [state]);

  return {
    status,
    error,
    resetError,
  };
}

function useNetworkStatus() {
  const [isOnline, setIsOnline] = useState<string>("pending");
  const [strength, setStrength] = useState("Checking");

  useEffect(() => {
    const updateOnlineStatus = () =>
      setIsOnline(navigator.onLine ? "online" : "offline");

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    const intervalId = setInterval(async () => {
      try {
        const startTime = new Date().getTime();
        const response = await fetch(
          "https://api.electricspeech.co/v1/monitoring/health/",
          { method: "GET" }
        );
        if (!response.ok) throw new Error("Network response was not ok");
        const endTime = new Date().getTime();
        const duration = (endTime - startTime) / 1000;
        const bytes = 49 * 8;
        const bps = bytes / duration;
        const kbps = bps / 1024;
        const mbps = bps / 1024;
        if (isOnline === "pending") {
          // @ts-ignore
          if (navigator.connection) {
            // @ts-ignore
            if (navigator.connection?.downlink <= 1) {
              setStrength("Weak");
              // @ts-ignore
            } else if (navigator.connection?.downlink <= 5) {
              setStrength("Moderate");
            } else {
              setStrength("Good");
            }
          } else {
            if (mbps <= 1) {
              setStrength("Weak");
            } else if (mbps <= 5) {
              setStrength("Moderate");
            } else {
              setStrength("Good");
            }
          }
        }
        setIsOnline("online");
      } catch (error) {
        setIsOnline("offline");
      }
    }, 3000);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
      clearInterval(intervalId);
    };
  }, []);

  return { isOnline, strength };
}

function Visualizer({
  name,
  reverse = false,
  image,
  strength,
  background,
  height,
  width,
  ranges,
  size,
}: {
  name: string;
  reverse?: boolean;
  image?: string;
  background?: string;
  strength?: number;
  height?: any;
  width?: any;
  ranges: number[];
  size?: any;
}) {
  return (
    <Flex
      gap={1}
      flexDirection={reverse ? "row-reverse" : "row"}
      justifyContent={"center"}
      alignItems={"end"}
      flex={0.5}
    >
      {reverse ? (
        <Box
          pos={"relative"}
          bottom={-6}
          border={"1px solid #c9c9c9"}
          borderRadius={"50%"}
          h={"36px"}
          w={"36px"}
        >
          <Text
            fontWeight={"semibold"}
            fontSize={"xs"}
            pos={"absolute"}
            left={"50%"}
            top={"50%"}
            transform={"translate(-50%, -50%)"}
          >
            {name}
          </Text>
        </Box>
      ) : (
        <Avatar
          bottom={reverse ? -6 : -12}
          name={name}
          w={size}
          border={"1px solid #c9c9c9"}
          h={size}
          src={image}
        />
      )}
      <Grid
        pos={"relative"}
        placeItems={"center"}
        background={background}
        borderBottomLeftRadius={reverse ? height : 8}
        borderBottomRightRadius={reverse ? 8 : height}
        borderTopRadius={height}
        border={"1px solid #E8E8E8"}
        h={height}
        w={width}
      >
        <Jiggle ranges={ranges} reverse={reverse} />
      </Grid>
    </Flex>
  );
}

const formatStringWithData = (template: string, data: any) => {
  const regex = /\{([^}]+)\}/g;
  const matches: any = template.matchAll(regex);
  let temp = template;
  for (const match of matches) {
    temp = temp.replaceAll(match[0], _.get(data, match[1]) || "");
  }
  return temp;
};


function CallLayout({ metadata, onConnect, onDisconnect }: { metadata?: any, onConnect?: (agent_id: string, metadata: any, callback?: () => void) => void, onDisconnect?: () => void }) {
  const [hasCallStarted, setHasCallStarted] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const vapi = useRef<Vapi | undefined>();
  const millis = useRef<any>();
  const [params] = useSearchParams();
  const [time, setTime] = useState(0);
  const [type, setType] = useState<"vapi" | "millis" | "livekit">();
  const timer = useRef<NodeJS.Timeout | number | undefined>();
  const [isTestLoading, setTestLoading] = useState(false);
  const toast = useToast();
  const [value, setValue] = useState<any>();
  const [assistant, setAssistantDetails] = useState<any>({});
  const [organization, setOrganizationDetails] = useState<any>({});
  const [hasDisconnected, setHasDisconnected] = useState(false);
  const { audioTrack, state } = useVoiceAssistant();
  const localParticipant = useLocalParticipant();
  const [callMetadata, setCallMetadata] = useState({});
  const { isOnline: onlineStatus, strength: networkStrength } =
    useNetworkStatus();
  const room = useRoomContext();
  const {
    status: microphoneStatus,
    error: microphoneError,
    resetError,
  } = useMicrophoneStatus(onlineStatus);
  const [userVolume, setUserVolume] = useState(1);
  const [agentVolume, setAgentVolume] = useState(1);
  const [agentAnalyser, setAgentAnalyser] = useState<
    AnalyserNode | undefined
  >();
  const [userAnalyser, setUserAnalyser] = useState<AnalyserNode | undefined>();
  const agentBars = useMultibandTrackVolume(
    useMemo(() => agentAnalyser, [agentAnalyser])
  );
  const userBars = useMultibandTrackVolume(
    useMemo(() => userAnalyser, [userAnalyser])
  );
  const [microphones, setMicrophones] = useState<MediaDeviceInfo[]>([]);
  const [speakers, setSpeakers] = useState<MediaDeviceInfo[]>([]);
  const [selectedMicrophone, setSelectedMicrophone] =
    useState<MediaDeviceInfo>();
  const [selectedSpeaker, setSelectedSpeaker] = useState<MediaDeviceInfo>();

  const fetchDevices = useCallback(async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const _microphones = [];
    const _speakers = [];
    let _selectedSpeaker = selectedSpeaker;
    let _selectedMicrophone = selectedSpeaker;
    for (const device of devices) {
      if (device.kind === "audioinput") {
        if (!_selectedMicrophone && device.deviceId === "default") {
          _selectedMicrophone = device;
          setSelectedMicrophone(device);
        } else {
          _microphones.push(device);
        }
      } else if (device.kind === "audiooutput") {
        if (!_selectedSpeaker && device.deviceId === "default") {
          _selectedSpeaker = device;
          setSelectedSpeaker(device);
        } else {
          _speakers.push(device);
        }
      }
    }
    setMicrophones(_microphones);
    setSpeakers(_speakers);
  }, [selectedMicrophone, selectedSpeaker]);

  useEffect(() => {
    if (!room) {
      return;
    }

    
    const handleParticipantConnected = (participant: RemoteParticipant) => {
      console.log('Remote participant connected:', participant.identity);
      
      // Add this to handle existing tracks
      participant.getTrackPublications().forEach(publication => {
        if (publication.track && publication.track.kind === Track.Kind.Audio) {
          console.log('Existing audio track found:', publication);
        }
      });

      // Handle new tracks
      participant.on(
        RoomEvent.TrackSubscribed,
        (track: Track, publication: RemoteTrackPublication) => {
          if (track.kind === Track.Kind.Audio) {
            console.log('Remote audio track subscribed:', publication);
          }
        }
      );

      // Add this to handle track unsubscribed
      participant.on(
        RoomEvent.TrackUnsubscribed,
        (track: Track, publication: RemoteTrackPublication) => {
          if (track.kind === Track.Kind.Audio) {
            console.log('Remote audio track unsubscribed:', publication);
          }
        }
      );
    };

    room.on(RoomEvent.ParticipantConnected, handleParticipantConnected);

    return () => {
      room.off(RoomEvent.ParticipantConnected, handleParticipantConnected);
    };
  }, [room]);

  const getLeadMetadata = async (assistant: string) => {
    const identifier =
      params.get("lead_id") ||
      params.get("user_phone_number") ||
      params.get("identifier");
    let call_metadata = {};
    if (identifier) {
      try {
        const request = await axios.get(
          `/agent/${assistant}/call-metadata?identifier=${identifier}`,
          {
            headers: {
              Authorization: `Bearer demo-key`,
            },
          }
        );
        const { data } = request.data;
        call_metadata = data || {};
        call_metadata = {
          ...call_metadata,
          ...((call_metadata as any).mandateInfo || {}),
        };
        delete (call_metadata as any).mandateInfo;
        setCallMetadata(data);
      } catch (err) {
        console.warn("Failed to fetch call metadata");
      }
    }
    return call_metadata;
  };

  const getAssistantDetails = async () => {
    const response = await axios.get(`/agent/${params.get("assistantId")}`, {
      headers: {
        Authorization: `Bearer demo-key`,
      },
    });
    setAssistantDetails(response.data);
    if (response.data.id) {
      getLeadMetadata(response.data.id);
    }
  };

  const getOrganizationDetails = async (org: string) => {
    const response = await axios.get(`/organization/${org}`, {
      headers: {
        Authorization: `Bearer demo-key`,
      },
    });
    setOrganizationDetails(response.data);
  };

  useEffect(() => {
    fetchDevices();
    window.navigator.mediaDevices.ondevicechange = (ev) => {
      fetchDevices();
      console.log(ev);
      (async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        for (const device of devices) {
          if (device.kind === "audioinput" && device.deviceId === "default") {
            const filteredDevices = devices.filter(
              (v) =>
                v.kind === "audioinput" &&
                v.deviceId !== "default" &&
                device.label.includes(v.label)
            );
            if (filteredDevices.length) {
              if (millis?.current?.audioService?.stream) {
                const stream = await navigator.mediaDevices.getUserMedia({
                  audio: true,
                  video: false,
                });
                // @ts-ignore
                stream.getTracks().forEach((track) => {
                  if (filteredDevices[0].label !== track.label) {
                    track.stop();
                  }
                });
                // @ts-ignore
                millis.current.audioService.stream =
                  await navigator.mediaDevices.getUserMedia({
                    audio: {
                      sampleRate: 16000,
                      deviceId: filteredDevices[0].deviceId,
                      echoCancellation: true,
                      noiseSuppression: true,
                      channelCount: 1,
                      autoGainControl: true,
                      // @ts-ignore
                      latency: 0,
                    },
                    video: false,
                  });
              }
            }
          }
        }
      })();
    };
    getAssistantDetails();
  }, []);

  useEffect(() => {
    if (assistant.organization_id) {
      getOrganizationDetails(assistant.organization_id);
    }
  }, [assistant]);

  const initiateCall = useCallback(async () => {
    setHasDisconnected(false);
    setTestLoading(true);
    if (!hasCallStarted) {
      if (type === "vapi") {
        await vapi.current?.start(params.get("agent")!);
      } else if (type === "millis") {
        const metadata = {
          ...callMetadata,
          ...((callMetadata as any)?.mandateInfo || {}),
        };
        delete metadata.mandateInfo;
        const urlParams = {
          ...Object.fromEntries(params.entries() || new Map()),
        };
        delete urlParams["$web_only"];
        await millis.current?.start(
          params.get("agent")!,
          {
            ...(metadata || {}),
            ...urlParams,
          },
          true
        );
      } else if (type === "livekit") {
        const metadata = {
          ...callMetadata,
          ...((callMetadata as any)?.mandateInfo || {}),
        };
        delete metadata.mandateInfo;
        const urlParams = {
          ...Object.fromEntries(params.entries() || new Map()),
        };
        delete urlParams["$web_only"];
        onConnect?.(assistant?.id, {
          ...(metadata || {}),
          ...urlParams,
        }, () => {
          setHasCallStarted(true)
          setTestLoading(false);
          toast({
            title: `Call started. You can now speak with ${
              assistant?.name || ""
            }`,
          });
          if (!timer.current) {
            timer.current = setInterval(() => {
              setTime((v) => v + 1);
            }, 1000);
          }
        })
      }
    }
  }, [type, millis, vapi, hasCallStarted, callMetadata, localParticipant]);

  const setupUserAnalysers = useCallback(async () => {
      const ctx = new AudioContext();
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: selectedMicrophone?.deviceId,
          echoCancellation: true,
          sampleRate: 16000,
          noiseSuppression: true,
          channelCount: 1,
          autoGainControl: true,
          // @ts-ignore
          latency: 0,
        },
        video: false
      })
      const source = ctx.createMediaStreamSource(stream);
      const analyser = ctx.createAnalyser();
      source.connect(analyser);
      setUserAnalyser(analyser)
  }, [selectedMicrophone])

  const setupAgentAnalysers = useCallback(async () => {
    const ctx = new AudioContext();
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: selectedSpeaker?.deviceId
      },
      video: false
    })
    const source = ctx.createMediaStreamSource(stream);
    const analyser = ctx.createAnalyser();
    source.connect(analyser);
    setAgentAnalyser(analyser)
}, [selectedSpeaker])

  useEffect(() => {
    if (hasCallStarted && selectedMicrophone && selectedSpeaker && type === "livekit" && room) {
      console.log("hello")
      localParticipant.localParticipant.setMicrophoneEnabled(true);
      setupAgentAnalysers()
      setupUserAnalysers()
    }
  }, [hasCallStarted, room, selectedMicrophone, selectedSpeaker, type])

  useEffect(() => {
    if (assistant?.id) {
      let provider = assistant?.provider?.toLowerCase();
      provider = "livekit"
      setType(provider);
      if (provider === "vapi") {
        if (!vapi.current) {
          if (params.get("key")) {
            vapi.current = new Vapi(params.get("key")!);
            vapi.current.on("call-end", () => {
              if (timer.current !== null) {
                clearInterval(timer.current);
              }
              setHasCallStarted(false);
            });
            vapi.current?.on("call-start", () => {
              setHasCallStarted(true);
              setTestLoading(false);
              toast({
                title: `Call started. You can now speak with ${
                  assistant?.name || ""
                }`,
              });
              if (!timer.current) {
                timer.current = setInterval(() => {
                  setTime((v) => v + 1);
                }, 1000);
              }
            });
          }
        }
      } else if (provider === "millis") {
        if (!millis.current) {
          if (params.get("key")) {
            millis.current = Millis.createClient({
              publicKey: params.get("key")!,
              debug: false,
              endPoint: "wss://api-eu-west.millis.ai/millis",
            });
            // @ts-ignore
            window.millis = millis.current;
            millis.current.on("analyzer", (analyzer: AnalyserNode) => {
              // @ts-ignore
              window.agentAnalyzer = analyzer;

              setAgentAnalyser(analyzer);
            });

            millis.current.on(
              "useraudioready",
              (data: { analyser: AnalyserNode; stream: MediaStream }) => {
                // @ts-ignore
                window.userAnalyzer = data.analyser;
                const analyzer = data.analyser;
                setUserAnalyser(analyzer);
              }
            );
            millis.current.on("onclose", () => {
              if (timer.current !== null) {
                clearInterval(timer.current);
              }
              setHasCallStarted(false);
            });
            millis.current?.on("onready", () => {
              console.log("Call ready");
              setHasCallStarted(true);
              setTestLoading(false);
              toast({
                title: `Call started. You can now speak with ${
                  assistant?.name || ""
                }`,
              });
              if (!timer.current) {
                timer.current = setInterval(() => {
                  setTime((v) => v + 1);
                }, 1000);
              }
            });
          }
        }
      }
    }
  }, [assistant, initiateCall]);

  return (
    <Box
      justifyContent={"space-between"}
      flexDirection={"column"}
      height={["calc(100dvh)", null, "100vh"]}
      overflow={"hidden"}
      pos={"relative"}
    >
      <Modal
        size={"xl"}
        isCentered
        isOpen={microphoneError !== undefined}
        onClose={() => {
          resetError();
        }}
      >
        <ModalOverlay />
        <ModalContent py={8} px={10}>
          <Stack gap={4}>
            <Heading fontSize={"2xl"}>{microphoneError?.title}</Heading>
            <Text fontSize={"lg"}>{microphoneError?.description}</Text>
            {microphoneError?.link ? (
              <Button
                w={"fit-content"}
                colorScheme="primary"
                variant={"outline"}
                rightIcon={<ArrowUpRightIcon size={20} />}
                href={microphoneError?.link}
                target="_blank"
                as={Link}
              >
                Click here to know more
              </Button>
            ) : (
              <></>
            )}
          </Stack>
        </ModalContent>
      </Modal>
      <Flex
        borderBottom={"1px solid #DADADA"}
        px={[2, null, 16]}
        py={[2, null, 4]}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Img
          h={["24px", null, "48px"]}
          src={formatStringWithData(
            assistant?.meta?.logo || organization?.image_url || Logo,
            callMetadata
          )}
        />
        <Flex alignItems={"center"} gap={[1, null, 4]}>
          {assistant?.phone_numbers?.length ? (
            <Button
              display={"none"}
              size={["sm", null, "md"]}
              px={[4, null, 10]}
              py={[2, null, 6]}
              background={"black"}
              color={"white"}
              rounded={"full"}
              leftIcon={<PhoneOutgoingIcon size={20} />}
            >
              Call me
            </Button>
          ) : (
            <></>
          )}
          <Button
            size={["sm", null, "md"]}
            px={[4, null, 10]}
            py={[2, null, 6]}
            rounded={"full"}
            color={"#0060FF"}
            background={"#DFEBFF"}
            gap={0}
          >
            <Box height={"24px"} w={"24px"} borderRadius={"50%"}>
              <svg className="blinking-dot">
                <circle
                  fill={
                    networkStrength === "Good"
                      ? "#0b9a06"
                      : networkStrength === "Moderate"
                      ? "#5f80fa"
                      : networkStrength === "Weak"
                      ? "#ffb24f"
                      : "#9797a5"
                  }
                  stroke="none"
                  cx="12"
                  cy="12"
                  r="6"
                >
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.1"
                  ></animate>
                </circle>
              </svg>
            </Box>
            <Text fontSize={["sm", null, "md"]} fontWeight={"medium"}>
              Network: {networkStrength}
            </Text>
          </Button>
        </Flex>
      </Flex>
      <Flex
        direction={["column", null, "row"]}
        alignItems={"center"}
        overflow={"hidden"}
        px={[4, 8, 24]}
        gap={[4, null, 72]}
        flexGrow={"100%"}
        pos={"relative"}
        py={4}
        className="call-container"
        height={["calc(100dvh - 17dvh)", null, "calc(100vh - 240px)"]}
      >
        <Divider
          display={["none", null, assistant?.meta?.title ? "block" : "none"]}
          pos={"absolute"}
          transform={"translate(-50%, -50%)"}
          left={"50%"}
          top={"50%"}
          flex={0}
          orientation="vertical"
          h={[0, null, "50vh"]}
        />
        {assistant?.meta?.title ? (
          <Stack gap={[2, null, 6]} flex={0.5}>
            <Stack>
              <Text fontWeight={"semibold"} fontSize={["md", null, "2xl"]}>
                {formatStringWithData(
                  assistant?.meta?.title || "",
                  callMetadata
                )}
              </Text>
              <Text fontSize={["sm", null, "md"]} color={"#6E6E6E"}>
                {formatStringWithData(
                  assistant?.meta?.subtitle || "",
                  callMetadata
                )}
              </Text>
            </Stack>
            <Stack
              boxShadow={"4px 4px 0 0 #D7DF20"}
              border={"1px solid #000000"}
              p={[2, null, 4]}
              gap={[2, null, 4]}
            >
              <Text
                fontSize={["md", null, "lg"]}
                textDecor={"underline"}
                fontWeight={"bold"}
              >
                {formatStringWithData(
                  assistant?.meta?.description,
                  callMetadata
                )}
              </Text>
              {(assistant?.meta?.instructions || []).map((e: string) => (
                <Text fontSize={["sm", null, "md"]}>
                  <Markdown skipHtml={false}>
                    {formatStringWithData(e, callMetadata)}
                  </Markdown>
                </Text>
              ))}
            </Stack>
          </Stack>
        ) : (
          <></>
        )}
        <Stack
          flex={assistant?.meta?.title ? 0.5 : 1}
          w={"100%"}
          justifyContent={assistant?.meta?.title ? "start" : "center"}
          gap={[16, null, 24]}
        >
          {hasDisconnected ? (
            <Stack alignItems={"center"}>
              <StarRating
                disabled={value !== undefined}
                value={value}
                setValue={(v) => {
                  setValue(v);
                  toast({
                    status: "success",
                    title: "Thank you for your feedback",
                  });
                  mixpanel.track("CALL_RATING", {
                    rating: v,
                    ...Object.entries(params.entries()),
                  });
                }}
                iconProps={{ className: "fill-yellow-500 stroke-yellow-500" }}
              />
              <Text size={"xl"} fontWeight={600}>
                {value === undefined
                  ? "Please rate your call experience"
                  : "Thank you for your feedback"}
              </Text>
            </Stack>
          ) : (
            <>
              <Flex alignItems={"start"}>
                <Visualizer
                  ranges={agentBars}
                  height={["130px", null, "170px"]}
                  width={["calc(75vw)", null, "360px"]}
                  size={["48px", null, "64px"]}
                  name={assistant?.name}
                  strength={agentVolume || 1}
                  image={assistant?.profile_pic}
                />
              </Flex>
              <Flex justifyContent={"end"} alignItems={"end"}>
                <Visualizer
                  ranges={userBars}
                  background="#000000"
                  strength={userVolume || 1}
                  reverse={true}
                  size={["24px", null, "36px"]}
                  height={["64px", null, "64px"]}
                  width={["150px", null, "150px"]}
                  name="You"
                />
              </Flex>
            </>
          )}
        </Stack>
      </Flex>
      <Flex
        flex={0.5}
        alignItems={"center"}
        gap={[1, null, 4]}
        h={["72px", null, "140px"]}
        justifyContent={"center"}
        py={[3, null, 6]}
        px={[1, null, 6]}
        borderTop={"1px solid #dedede"}
      >
        {microphones?.length ? (
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  isDisabled={hasDisconnected}
                  as={Button}
                  background={"#0060FF"}
                  _hover={{
                    background: "#0060FF",
                  }}
                  rightIcon={<ChevronUpIcon color="white" />}
                  rounded={"lg"}
                  size={["md", null, "lg"]}
                  px={3}
                  leftIcon={
                    isMuted ? (
                      <MicOffIcon color="white" size={20} />
                    ) : (
                      <MicIcon color="white" size={20} />
                    )
                  }
                >
                  <Divider
                    orientation="vertical"
                    background={"white"}
                    height={8}
                  />
                </MenuButton>
                <MenuList background={"black"} color={"white"}>
                  {microphones.map((d) => (
                    <MenuItem
                      icon={
                        selectedMicrophone?.label?.includes(d.label) ? (
                          <CheckIcon color="white" size={16} />
                        ) : undefined
                      }
                      background={"black"}
                      color={"white"}
                      onClick={async () => {
                        // @ts-ignore
                        if (millis?.current?.audioService?.stream) {
                          const stream =
                            await navigator.mediaDevices.getUserMedia({
                              audio: true,
                              video: false,
                            });
                          // @ts-ignore
                          stream.getTracks().forEach((track) => {
                            if (d?.label !== track.label) {
                              track.stop();
                            }
                          });
                          // @ts-ignore
                          millis.current.audioService.stream =
                            await navigator.mediaDevices.getUserMedia({
                              audio: {
                                deviceId: d?.deviceId,
                                echoCancellation: true,
                                sampleRate: 16000,
                                noiseSuppression: true,
                                channelCount: 1,
                                autoGainControl: true,
                                // @ts-ignore
                                latency: 0,
                              },
                              video: false,
                            });
                        } else if (room) {
                            room.switchActiveDevice("audioinput", d.deviceId)
                        }
                        setSelectedMicrophone(d);
                      }}
                    >
                      {d.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </>
            )}
          </Menu>
        ) : (
          <></>
        )}
        {speakers?.length ? (
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  isDisabled={hasDisconnected}
                  as={Button}
                  _hover={{
                    background: "#0060FF",
                  }}
                  background={"#0060FF"}
                  rightIcon={<ChevronUpIcon color="white" />}
                  rounded={"lg"}
                  size={["md", null, "lg"]}
                  leftIcon={<Volume2Icon color="white" size={20} />}
                >
                  <Divider
                    orientation="vertical"
                    background={"white"}
                    height={8}
                  />
                </MenuButton>
                <MenuList background={"black"} color={"white"}>
                  {speakers.map((d) => (
                    <MenuItem
                      icon={
                        selectedSpeaker?.label?.includes(d.label) ? (
                          <CheckIcon color="white" size={16} />
                        ) : undefined
                      }
                      background={"black"}
                      color={"white"}
                      onClick={() => {
                        setSelectedSpeaker(d);
                        console.log(millis.current?.audioService.audioContext);
                        if (millis.current) {
                          millis.current?.audioService.audioContext.setSinkId(
                            d.deviceId
                          );
                        } else {
                          room.switchActiveDevice("audiooutput", d.deviceId)
                        }
                      }}
                    >
                      {d.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </>
            )}
          </Menu>
        ) : (
          <></>
        )}
        {!hasCallStarted ? (
          <Tooltip
            placement="top"
            label={
              microphoneStatus === "error"
                ? "Cannot start call with microhone"
                : ""
            }
          >
            <Button
              isDisabled={microphoneStatus === "error" || hasDisconnected}
              isLoading={microphoneStatus === "pending" || isTestLoading}
              onClick={async () => {
                mixpanel.track("START_CALL", Object.entries(params.entries()));
                await initiateCall();
              }}
              _hover={{ background: "#D7DF20" }}
              background={"#D7DF20"}
              fontSize={"lg"}
              rounded={"full"}
              height={["48px", null, "64px"]}
              w={240}
            >
              Talk with {assistant?.name?.split(" ")?.[0]}
            </Button>
          </Tooltip>
        ) : (
          <Flex
            rounded={12}
            px={[3, 0, 4]}
            py={2}
            ml={[0, null, 4]}
            background={"#E53E3E"}
            alignItems={"center"}
            gap={2}
          >
            <Text color={"white"} fontSize={"xl"} fontWeight={600}>{`${String(
              Math.floor((time % 3600) / 60)
            ).padStart(2, "0")}:${String(time % 60).padStart(2, "0")}`}</Text>
            <Divider h={8} orientation="vertical" />
            <Button
              pr={1}
              leftIcon={<PhoneOffIcon size={20} />}
              onClick={() => {
                setHasDisconnected(true);
                if (type === "vapi") {
                  vapi.current?.stop();
                } else {
                  millis.current?.stop();
                }
                if (timer.current !== null) {
                  clearInterval(timer.current);
                  timer.current = undefined;
                }
                setTime(0);
                setHasCallStarted(false);
              }}
              colorScheme="red"
            >
              End Call
            </Button>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}


export default function CallV2() {
  const [token, setToken] = useState<string | undefined>()

  const connect = useCallback(
    async (agent_id: string, metadata: any, callback?: () => void) => {
      let token = '';
      try {
        const request = await axios.post(
          `${process.env.REACT_APP_API_URL}/interactions/initiate`,
          {
            agent_id,
            metadata
          }
        );
        const response = request.data;
        const { token: accessToken } = response;
        token = accessToken;
        setToken(token)
      } catch (err) {
       
      } finally {
        callback?.()
      }
    },
    []
  );

  const disconnect = useCallback(async () => {
    setToken(undefined)
  }, []);


  useEffect(() => {

  }, [])

  return (
    <Box>
      {/* @ts-ignore */}
      <LiveKitRoom
        serverUrl={process.env.REACT_APP_LIVEKIT_URL}
        token={token}
        connect={token !== undefined}
      >
        <CallLayout onConnect={connect} onDisconnect={disconnect} />
      </LiveKitRoom>
    </Box>
  )
}